<!-- 
	@component
	
	COMPANY LOGO
	
	@prop symbol - The symbol of the company.
	@prop alt - The alt text of the image.
	@prop title - The title of the image.
	@prop height - The height of the image.
	@prop width - The width of the image.
	@prop rounded - The border radius of the image.
	@prop background - The background color of the image.
	@prop border - The border of the image.
	@prop classes - The classes to apply to the image.
	

 -->

<script context="module" lang="ts">
	const DEFAULT_LOGO_PATH = 'default-logo.png';

	/**
	 * @description Certain things should be ensured before inlining the image.
	 * Most notable, supabase storage requires that the file name case match EXACTLY what is in the database.
	 * We use uppercase to insert the images, so we need to use uppercase to get the correct image, as well.
	 * @param s - The symbol of the instrument.
	 * @returns The path to the image.
	 */
	function getPath(s?: string) {
		if (!s) {
			return DEFAULT_LOGO_PATH;
		}

		// If a slash is in the symbol, it is a crypto.
		// If this is the case -> replace it with a colon and remove everything after it.
		// By removing everything after it, we ensure that cryptos will match against ALL currencies, not just the USD.
		// Ex. `BTC/USD`, `BTC/USDT`, `BTC/USDC`, etc. will all match `BTC`.
		if (s.includes('/')) {
			return `crypto/${s.split('/')[0].toUpperCase()}.png`;
		}

		return `${s.toUpperCase()}.png`;
	}
</script>

<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';
	import type { State } from '@twicpics/components/sveltekit';

	import Image from './Image.svelte';

	export let symbol: string;
	export let alt: string = '';
	export let title: string | undefined = undefined;
	export let height: CssClasses = 'h-6';
	export let width: CssClasses = 'w-6';
	export let rounded: CssClasses = 'rounded-md';
	export let background: CssClasses = 'bg-surface-50';
	export let border: CssClasses = '';
	export let classes: CssClasses = '';

	let state: State = 'new';
	$: path = getPath(symbol);
	$: if (state === 'error') {
		path = DEFAULT_LOGO_PATH;
		// Only set border, IF a border override was not already provided.
		if (border === '') {
			border = 'border border-primary-500';
		}
	}
</script>

<Image
	{alt}
	classes="{height} {width} shrink-0 {background} {rounded} {border} brightness-90 duration-300 group-hover:brightness-100 {classes}"
	extensionImgOverrides={{
		imgClasses: `w-full h-full object-cover ${rounded}`,
		wrapperClasses: `overflow-hidden !shrink-0 ${background} ${border} ${height} ${width} ${rounded} ${classes}`
	}}
	mode="contain"
	placeholder="maincolor"
	ratio="1:1"
	src="/logos/{path}"
	{title}
	bind:state
/>
